@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* color: black !important; */
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #888;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #4567d6;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiListItem-root:hover {
  background: rgba(128, 128, 128, 0.222);
  border-radius: 10px;
}

img {
  width: 100%;
  height: 100%;
}

.loading_title {
  background: rgba(5, 132, 205, 0.615);
  padding: 5px 15px;
  border-radius: 10px;
  text-transform: capitalize;
  color: white !important;
  font-weight: bolder;
  margin-top: 10px;
}

/* input {
  width: 100%;
} */

.site-navbar-right-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 4999;
}

.site-navbar-link {
  margin: 0;
  margin-right: 15px;
  text-decoration: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer !important;
}

.site-navbar-link:hover {
  color: grey;
}

/* Projects list */
.projects_list {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: white;
}

.projects_list.left {
  justify-content: flex-start !important;
}

.projects_list .project {
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  cursor: pointer;
  transition: 0.25s;
}

.projects_list .project:hover {
  transform: scale(1.1);
}

.projects_list .project .image {
  width: 80px;
  height: 80px;
  overflow: hidden;
}

.projects_list .project span {
  font-size: 80%;
  text-align: center;
  width: 100%;
  padding: 0;
}

.projects_list .project.active span {
  font-weight: bolder;
}

.sub_products_list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 50px;
}

.sub_products_list .product {
  width: calc(50% - 20px);
  height: 400px;
  margin: 10px;
  border-radius: 10px;
  border: 1px solid grey;
  transition: 0.25s;
  cursor: pointer;
}

.sub_products_list .product:hover {
  transform: scale(1.04);
}

.sub_products_list .product img {
  /* object-fit: cover; */
}

/* Store */
#store {
  width: 100%;
  min-height: 100vh;
}

/* Single Product */
#single_product {
  width: 100%;
  min-height: calc(100vh - 59px);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 60px;
}

#single_product .left {
  width: 40%;
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  top: 10px;
  margin-right: 10px;
}

#single_product .left .cover_image {
  background: yellow;
  width: 100%;
  transition: 0.25s;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
}

#single_product .left .cover_image:hover {
  transform: scale(1.01);
}

#single_product .left .images_list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

#single_product .left .images_list .image {
  width: 80px;
  height: 80px;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 -2px 10px black;
  cursor: pointer;
  transition: 0.25s;
}

#single_product .left .images_list .image:hover {
  transform: scale(1.1);
}

#single_product .left .images_list .image img {
  object-fit: cover;
}

#single_product .right {
  width: 60%;
  font-size: 100%;
}

#single_product .right .title {
  font-weight: bolder;
  font-size: 150%;
}

#single_product .right .short_desc {
  font-size: 100%;
}

#single_product .right .prices {
  display: flex;
  align-items: flex-end;
}

#single_product .right .sale_price {
  margin-right: 10px;
  font-size: 120%;
  font-weight: bolder;
}

#single_product .right .mrp {
  margin-right: 10px;
  font-size: 80%;
  text-decoration: line-through;
}

#single_product .right .discount {
  font-size: 80%;
  margin-right: 10px;
  color: green !important;
  font-weight: bold;
}

#single_product .right .long_desc {
  background: rgba(128, 128, 128, 0.334);
  padding: 10px;
  border-radius: 10px;
  margin: 20px 0;
}

#single_product .right .long_desc>span {
  font-weight: bolder;
  cursor: pointer;
}

#single_product .right h5 {
  border-bottom: 1px solid grey;
}

#single_product .right .details_list {
  display: flex;
  flex-direction: column;
}

#single_product .right .detail {
  display: flex;
}

#single_product .right .detail:nth-child(even) {
  background: rgba(215, 215, 215, 0.604);
}

#single_product .right .key_name {
  width: 20%;
  font-weight: bolder;
  padding-right: 10px;
}

#single_product .right .key_value {
  width: 50%;
  padding-left: 10px;
}

/* Checkout */
#checkout {
  width: 100%;
  min-height: 100vh;
}

#checkout .shipping_address {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 40%;
}

#checkout .shipping_address .row {}

#checkout .shipping_address .row .MuiTextField-root {}

#checkout .shipping_address .MuiTextField-root {
  margin-bottom: 10px;
  width: 100%;
}

.primary-btn {
  background: linear-gradient(90deg, #229e62, #202c99);
  padding: 6px 15px;
  border-radius: 20px;
  color: white;
}

.primary-btn:hover {
  filter: brightness(0.7)
}

.primary-btn>a {
  text-decoration: none !important;
}
.primary-btn>a:hover {
  color: white !important;
}

.menuList-active{
  top: 70px !important
}

.gallery-item{
  margin: 10px 0 !important;
}

@media screen and (min-width: 500px) {
  .gallery-item{
    margin: 100px 5px 0 !important;
  }
}